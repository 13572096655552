<template>
  <div class="w100p ml6-dk mr6-dk mt5" v-if="!loading">
    <h1 class="text-left f2-5 ma0 f1-5-mb mt2-mb">Change Password</h1>
    <p class="font-axiroma-book text-left mb4 text-grey f1-3 mt0 mb1-mb">Enter new password to reset.</p>
    <div class="border-grey w30p-dk">
      <div class="display-flex border-bottom w100p h6" :class="{ 'bg-grey br0-5 ': userDetails.currentPassword.length }">
        <bh-input
          class="w100p pl2"
          type="password"
          placeholder="Enter current password"
          :value="userDetails.currentPassword"
          @output="updateValue(userDetails, 'currentPassword', $event)"
          required
          />
      </div>
      <div class="display-flex align-center border-bottom w100p h6" :class="{ 'bg-grey br0-5 ': userDetails.password.length }">
        <bh-input
          class="w100p pl2"
          type="password"
          placeholder="Enter new Password"
          :value="userDetails.password"
          @output="updateValue(userDetails, 'password', $event)"
          required />
        <bh-icon v-if="isPasswordsValid" icon="check-mark" class="inline f2 pa2 pa1-mb mt0--5 mt0-mb text-success" />
      </div>
      <div class="display-flex w100p h6"  :class="{ 'bg-grey br0-5 ': userDetails.confirmPassword.length }">
        <bh-input
        class="w100p pl2"
        type="password"
        placeholder="Re-enter new Password"
        :value="userDetails.confirmPassword"
        @output="updateValue(userDetails, 'confirmPassword', $event)"
        required
        />
        <bh-icon v-if="isPasswordsValid" icon="check-mark" class="inline f2 pa2 pa1-mb mt0--5 mt0-mb text-success" />
      </div>
    </div>
    <span class="display-flex mt1 text-danger f1-5" v-if="errorMessage">
      {{ errorMessage }}
    </span>
    <div class="mt2 display-flex justify-center w30p-dk">
      <bh-button
        @output.self="submit"
        :disabled="!isPasswordsValid"
        class="pa1 h6 br0-5 f1-6 bg-violet text-white border-violet box-shadow-light"
        btnClass="w100p h6 br0-5 bg-violet text-white f2 pointer justify-center align-center border-none"
        >
        <bh-loader v-if="saving" color="#ffffff"/>
        <span v-else>Save</span>
      </bh-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { updateValue, validatePassword } from '@/utils'
import { User } from '@/services/user'

export default {
  name: 'tbh-change-password',
  data() {
    return {
      userDetails: {
        password: '',
        confirmPassword: '',
        currentPassword: ''
      },
      errorMessage: '',
      saving: false
    }
  },
  computed: {
    ...mapGetters(['loading']),
    /**
     * @method isPasswordsValid
     * checks whether password and confirm password are the same and does validations
     * @returns { Object }
    */
    isPasswordsValid() {
      const { password, confirmPassword, currentPassword } = this.userDetails
      if (password && confirmPassword && currentPassword) {
        return validatePassword(password) && password === confirmPassword
      }
    }
  },
  watch: {
    'userDetails.currentPassword'(value) {
      this.errorMessage = null
      if (!value) this.errorMessage = 'Enter your current password'
    },
    'userDetails.password'(value) {
      this.errorMessage = null
      if (value) {
        if (!validatePassword(value)) this.errorMessage = 'Enter a valid password'
      }
    },
    'userDetails.confirmPassword'(value) {
      if (value) {
        this.errorMessage = null
        if (this.userDetails.password !== value) {
          this.errorMessage = 'Passwords are not matched'
        }
      }
    }
  },
  methods: {
    ...mapActions(['setSingleProperty']),
    updateValue,
    /**
     * @method submit
     * @param - event - { Object }
     */
    async submit() {
      if (this.errorMessage || !this.isPasswordsValid) return
      let toast = null
      try {
        this.saving = true
        await User.changePassword({ oldPassword: this.userDetails.currentPassword, newPassword: this.userDetails.password })
        toast = { duration: 3000, show: true, message: 'Your password has been changed successfully.' }
      } catch (err) {
        toast = { duration: 3000, show: true, message: err?.error?.message || 'Invalid Details, Please check', color: '#F44336' }
      }
      this.saving = false
      this.setSingleProperty(['toast', toast])
    }
  }
}
</script>
